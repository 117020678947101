import request from '@/utils/request'

export function uploadFile(file) {
    // 创建 FormData 对象
    const formData = new FormData();
    formData.append('file', file);  // 'file' 是后端接收的参数名
  
    // 发送 POST 请求，传递文件
    return request.post('/diary/file/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // 设置请求头为 multipart/form-data
      },
    });
  }

  export function deleteFile(params) {
    return request.get('/diary/file/params',{params})
}